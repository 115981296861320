<template>
  <v-select
    class="rounded-lg align-center color-field" :items="colors" :value="value"
    outlined dense append-icon="" :disabled="disabled"
    :messages="''"
    :error-messages="errorMessages" @input="$emit('input', $event)" @blur="$emit('blur')"
  >
    <template #selection="{ item }">
      <div class="rounded-lg w-100 item" :style="{ background: item.hex, border: `1px solid ${$vuetify.theme.themes.light.grey50}` }" />
    </template>
    <template #item="{ item }">
      <div class="w-100 item" :style="{ background: item.hex }" />
    </template>
  </v-select>
</template>
<script>
export default {
  name: 'ColorField',
  props: {
    value: { type: String, default: null },
    errorMessages: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    colors: () => [
      { value: 'white', hex: '#FFFFFF' },
      { value: 'black', hex: '#0F0F0F' },
      { value: 'yellow', hex: '#F8C125' },
      { value: 'blue', hex: '#3A5CB6' },
      { value: 'red', hex: '#DF5555' },
      { value: 'green', hex: '#89D184' },
      { value: 'purple', hex: '#9349B6' },
      { value: 'orange', hex: '#FF8F45' },
    ],
  },
}
</script>
<style lang="scss" scoped>
.color-field {
  width: 42px;
  max-width: 42px;
}

.item {
  height: 24px;
}

::v-deep .v-list-item {
  padding: 0;
  min-height: 24px;
}

::v-deep .v-input__slot {
  padding: 0 !important;
  min-height: 24px !important;
  height: 24px;
}

.v-list {
  padding: 0 !important;
  width: 43px;
}

::v-deep .v-text-field__details {
  max-width: inherit;
}

::v-deep  fieldset {
  border: 1px solid currentColor !important;
}

.v-menu__content {
  min-width: 43px !important;
}

</style>
